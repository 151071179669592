import { Button, Modal, Toast } from 'antd-mobile';
import { signMessage, getAccounts } from 'w3/eth';
import {
  LoginResDto,
  getLoginCode,
  getPrice,
  getReferer,
  getReferralCode,
  login,
} from 'api/user';
import { useRequest } from 'ahooks';
import { useState, useEffect } from 'react';
import { RefererPopup, useHook as useRefererHook } from './RefererPopup';
import { useWeb3Events } from 'w3/eth';
import bgBannerPng from 'asset/img/bg-banner.png';
import styles from './Home.module.scss';
import devicePng from 'asset/img/device.png';
import nftPng from 'asset/img/nft.png';
import { ProductPriceDto } from '../api/dto';
import { BuyModal, useBuyModal } from './BuyModal';
import section2Img from 'asset/img/section2.png';
import section31 from 'asset/img/section3-1.png';
import section32 from 'asset/img/section3-2.png';
import section33 from 'asset/img/section3-3.png';
import section34 from 'asset/img/section3-4.png';
import section35 from 'asset/img/section3-5.png';
import section36 from 'asset/img/section3-6.png';
import AtomOSImg from 'asset/img/atomOS.png';
import hb2Img from 'asset/img/hb2.png';
import hb3Img from 'asset/img/hb3.png';
import p1Img from 'asset/img/p1.png';
import p2Img from 'asset/img/p2.png';
import p3Img from 'asset/img/p3.png';
import { useLocation } from 'react-router-dom';
import { useApp } from 'App/useApp';

export function Home() {
  const refererHook = useRefererHook();
  const { token, setToken } = useApp();
  const buyModalHook = useBuyModal();
  const [queryCode, setQueryCode] = useState('');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // 获取特定查询参数的值
    const code = searchParams.get('code');
    if (code) {
      setQueryCode(code);
    }
    if (!window.ethereum) {
      Modal.alert({
        title: '请在钱包软件打开此页面或者安装MetaMask',
        onClose() {
          Toast.show({
            duration: 0,
            content: '请在钱包软件打开此页面或者安装MetaMask',
            maskClickable: false,
          });
        },
      });
    }
  }, []);

  useWeb3Events(
    () => {
      window.sessionStorage.clear();
      window.location.reload();
    },
    () => {
      window.sessionStorage.clear();
      window.location.reload();
    },
  );

  const { data: address = '', loading: getAccountsLoading } = useRequest(
    () => {
      return getAccounts().then((res) => res[0]);
    },
    {
      onError(e) {
        Modal.show({
          content: '请检查您的钱包软件',
          closeOnMaskClick: true,
        });
      },
    },
  );
  const { run: runLogin, loading: runGetLoginCodeLoading } = useRequest(
    async (address): Promise<LoginResDto> => {
      let loginCode = await getLoginCode(address);
      let signature = await signMessage(loginCode);
      return login(address, signature);
    },
    {
      manual: true,
      onSuccess(res: LoginResDto) {
        if (res) {
          const { token, tokenData } = res;
          setToken(token);
          window.sessionStorage.setItem('token', token);
          window.sessionStorage.setItem('address', address);
          window.sessionStorage.setItem('tokenData', JSON.stringify(tokenData));
        }
      },
      onError(e) {
        console.log(e);
        if (e.message.match('User denied message signature')) {
          Modal.show({
            content: '请用钱包软件签名登录',
            closeOnMaskClick: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          Modal.show({
            content: e.message,
            closeOnMaskClick: true,
          });
        }
      },
    },
  );

  const { run: runGetReferer, loading: runGetRefererLoading } = useRequest(
    getReferer,
    {
      manual: true,
      onSuccess(res: string) {
        if (res !== undefined && !res) {
          if (queryCode) {
            refererHook.runSetReferer(queryCode);
          } else {
            refererHook.setVisible(true);
          }
        }
        console.log(res);
      },
    },
  );

  const { run: runGetReferralCode, loading: runGetReferralCodeLoading } =
    useRequest(getReferralCode, {
      manual: true,
      onSuccess(res: string) {
        console.log(res);
      },
    });

  const {
    run: runGetPrice,
    loading: runGetPriceLoading,
    data: priceObj = {
      deviceNft: 0,
      nft: 0,
    },
  } = useRequest(getPrice, {
    manual: true,
    onSuccess(res: ProductPriceDto) {
      console.log(res);
    },
  });

  useEffect(() => {
    console.log('check need login');
    if (!address) {
      return;
    }
    if (
      window.sessionStorage.getItem('token') &&
      window.sessionStorage.getItem('address') === address
    ) {
      setToken(window.sessionStorage.getItem('token') || '');
    } else {
      window.sessionStorage.clear();
      if (address) {
        runLogin(address);
      }
    }
  }, [address, runLogin]);

  useEffect(() => {
    if (token && address) {
      runGetReferer();
      runGetReferralCode();
      runGetPrice();
    }
  }, [token, address, runGetReferer, runGetReferralCode, runGetPrice]);

  useEffect(() => {
    if (
      runGetLoginCodeLoading ||
      runGetRefererLoading ||
      runGetPriceLoading ||
      runGetReferralCodeLoading ||
      getAccountsLoading
    ) {
      Toast.show({
        duration: 0,
        icon: 'loading',
        maskClickable: false,
      });
    } else {
      Toast.clear();
    }
  }, [
    runGetLoginCodeLoading,
    runGetRefererLoading,
    runGetPriceLoading,
    runGetReferralCodeLoading,
    getAccountsLoading,
  ]);

  return (
    <div className={styles['main']}>
      <section
        style={{ backgroundImage: `url(${bgBannerPng})` }}
        className={styles['section1']}
      >
        <div style={{ height: '50%' }}></div>
        <div className={styles['product-box']}>
          <h3>Deeper 网关 + NFT</h3>
          <div className={styles['product-row']}>
            <div className={styles['product-item']}>
              <div className={styles['img-box']} style={{ background: '#fff' }}>
                <img src={devicePng} alt="" />
              </div>
              <div className={styles['price-box']}>
                <div>价格：</div>
                <div className={styles['price']}>$ {priceObj.deviceNft}</div>
              </div>
            </div>
            <div className={styles['product-item']}>
              <div className={styles['img-box']}>
                <img src={nftPng} alt="" />
              </div>
              <div className={styles['price-box']}>
                <div>价格：</div>
                <div className={styles['price']}>$ {priceObj.nft}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['buy-box']}>
          <BuyModal priceObj={priceObj} theHook={buyModalHook}></BuyModal>
          <div className={styles['btn-box']}>
            <Button
              onClick={() => {
                buyModalHook.openBuy('device+NFT');
              }}
              block
              color="primary"
            >
              购买网关+NFT
            </Button>
          </div>
          <div className={styles['btn-box']}>
            <Button
              disabled
              onClick={() => {
                buyModalHook.openBuy('NFT');
              }}
              block
              color="primary"
            >
              购买NFT
            </Button>
          </div>
        </div>
      </section>
      <section className={styles['section-box']}>
        <h3>
          DEEPER <span className={styles['highlight']}>NETWORK</span>
        </h3>
        <div className={styles['content-box']}>
          <h4>关于我们</h4>
          <div>
            DeeperNetwork是一家专注于网络隐私和安全的公司。开发和提供了一种基于区块链技术的网络隐私保护解决方案，旨在为用户提供一种安全、匿名、无广告的上网体验。Deeper
            Network的产品中包括DeeperConnect
            Mini，这是一款私人智能云网关，可以为用户提供安全的互联网访问、隐私保护和去中心化的网络连接。通过Deeper
            Network的解决方案，用户可以避免被追踪和监控，同时也能防止黑客入侵和恶意软件攻击。
          </div>
        </div>
      </section>
      <div className={styles['section-img-box']}>
        <img src={section2Img} alt="" />
      </div>

      <section className={styles['section-box']}>
        <h3>
          ADSC终端媒介- <span className={styles['highlight']}>创享主权</span>
        </h3>
        <div className={styles['section3-content-box']}>
          <div>
            <img src={section31} alt="" />
            <div>资本进入门槛低</div>
          </div>
          <div>
            <img src={section32} alt="" />
            <div>无技术进入壁垒</div>
          </div>
          <div>
            <img src={section33} alt="" />
            <div>没有网络安全问题</div>
          </div>
          <div>
            <img src={section34} alt="" />
            <div>智能应用合约</div>
          </div>
          <div>
            <img src={section35} alt="" />
            <div>链上购买服务</div>
          </div>
          <div>
            <img src={section36} alt="" />
            <div>惠及所有物联网用户</div>
          </div>
        </div>
      </section>
      <section className={styles['section-box']}>
        <h3>
          ADSC互联网与区块链的
          <span className={styles['highlight']}>再次深度交融</span>
        </h3>
        <div className={styles['content-box']}>
          <h4>Deeper Web3.0</h4>
          <div>
            区块链目前已进入第二个10年的迅速发展时期，从web1.0到web2.0时代，逐步触碰到3.0的实质价值！以往区块链的诞生发展融合互联网的升级，势必达成永续环境，区块链已向未来发展跨进了一大步！而包含一切万物的底层协议，仍然要从基础设施的最开始给到用户真正主权价值，从而达成人人去中心化的身份以及权益，这就是Adsc赋予的价值回归。跨世纪网络处理终端，给到持有者创享世界的主权机会！
          </div>
        </div>
      </section>

      <section className={styles['section-box']}>
        <h3>
          ADSC互联网与区块链的
          <span className={styles['highlight']}>价值回归用户主权</span>
        </h3>
        <div className={styles['content-box']}>
          <div>
            <img
              style={{ width: '100%', marginBottom: 10 }}
              src={AtomOSImg}
              alt=""
            />
          </div>
          <h4>AtomOS</h4>
          <div>
            AtomOS操作系统是世界上第一个考虑到速度和安全性而构建的无锁网络操作系统。使用零拷贝技术直接从网络设备读取数据包进行处理，与传统操作系统（例如Linux、FreeBSD等）相比，数据包转发速度提高了十倍。AtomOS还提供了一个7层防火墙，可以防御深度攻击。
          </div>
        </div>
      </section>

      <section className={styles['section-box']}>
        <h3>
          ADSC主权
          <span className={styles['highlight']}>环境下的构成</span>
        </h3>
        <div className={styles['content-box']}>
          <div>
            <img
              style={{ width: '100%', marginBottom: 10 }}
              src={hb2Img}
              alt=""
            />
          </div>
          <h4>Deeper Network 流量主权</h4>
          <div>
            互联⽹流量以及区块链流量的价值，最终回溯⾄开发者⼿中流量的价值变现对于⽤⼾也只仅有⾃⼰的流量变现，况且在依据平台扩散流量的过程中，实际价值会进⾏集权化的分散，那么今天ADSC解决⾸发创新，将流量端⼝聚集终端，终端布局之地，都可享受平台以及系统成⻓过程的所有分润系统，不会在存在后续资⾦强制购买以及暗属性潜规则的事情出现，开创流量共享之地！
          </div>
        </div>
        <div className={styles['content-box']}>
          <div>
            <img
              style={{ width: '100%', marginBottom: 10 }}
              src={hb3Img}
              alt=""
            />
          </div>
          <h4>Deeper Network 数据主权</h4>
          <div>
            如果流量作为⼀个端⼝，那么数据就是管道收益的根本，数据是信息，是个⼈，也可以是所有，互联⽹的拓展集权以将数据隐私全部抛弃个⼈，区块链的可追溯也并没有将实质信息价值给到个⼈，如ipfs存储，只是存放价值，并未因为布局的⽅式带来服务价值，今天的ADSC终端布局，结合AI的⽅式，终端作为互联⽹与区块链的跳板，将个⼈信任体系给到终端，终端来识别根据主⼈的特性拦截，扫描各类信息，以价值回归的⽅式作为媒介执⾏，让持有者享受到⽹络安全升级的价值！
          </div>
        </div>
      </section>

      <section className={styles['section-box']}>
        <h3>
          合作
          <span className={styles['highlight']}>伙伴</span>
        </h3>
        <div className={styles['section-img-box']}>
          <img src={p1Img} alt="" />
        </div>
        <div className={styles['section-img-box']}>
          <img src={p2Img} alt="" />
        </div>
        <div className={styles['section-img-box']}>
          <img src={p3Img} alt="" />
        </div>
      </section>

      {address && (
        <RefererPopup theHook={refererHook} address={address}></RefererPopup>
      )}
    </div>
  );
}
