import { useRequest } from 'ahooks';
import { getReward } from 'api/user';
import moment from 'moment';
import styles from './Reward.module.scss';
import { SpinLoading } from 'antd-mobile';
import { useState } from 'react';
export type RewardItemDto = {
  amount: number;
  _id: string;
  createdAt: string;
  orderId: string;
  reason: string;
};

export function Reward() {
  const [totalReward, setTotalReward] = useState(0);
  const { data: list = [], loading: listLoading } = useRequest(getReward, {
    onSuccess(res) {
      console.log(res);
      let total = 0;
      res
        .filter((it: RewardItemDto) => it.reason !== 'MAINAGENT')
        .forEach((it: RewardItemDto) => {
          total = total + it.amount / 1;
        });
      setTotalReward(total);
    },
  });

  return (
    <div>
      <div className={styles['total-box']}>
        <div>总奖励：</div> <div style={{ fontSize: 18 }}>{totalReward} USDT</div>
      </div>
      {listLoading ? (
        <div className={styles['loading-box']}>
          <SpinLoading></SpinLoading>
        </div>
      ) : (
        <div className={styles['table-box']}>
          <table>
            <thead>
              <tr>
                <th>订单编号</th>
                <th>奖励</th>
                <th>奖励时间</th>
              </tr>
            </thead>
            <tbody>
              {list
                .filter((it: RewardItemDto) => it.reason !== 'MAINAGENT')
                .map((it: RewardItemDto) => {
                  return (
                    <tr key={it._id}>
                      <td style={{ wordBreak: 'break-all' }}>{it.orderId}</td>
                      <td>{it.amount} USDT</td>
                      <td>{moment(it.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
