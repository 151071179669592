import { useApp } from 'App/useApp';
import { useRequest } from 'ahooks';
import { canInvite, getDeadline } from 'api/user';
import { useEffect, useState } from 'react';

function getTokenData() {
  if (!window.sessionStorage.getItem('tokenData')) {
    return {};
  }
  return JSON.parse(window.sessionStorage.getItem('tokenData') || '');
}

export function useTokenData() {
  const { token } = useApp();
  const [tokenData, setTokenData] = useState<{
    role: string;
    username: string;
  }>({
    role: '',
    username: '',
  });
  useEffect(() => {
    if (token) {
      const tokenData = getTokenData();
      setTokenData(tokenData);
    }
  }, [token]);
  return tokenData;
}

export function useCanInvite() {
  const { token } = useApp();
  const {
    data = false,
    loading,
    run: runCanInvite,
  } = useRequest(canInvite, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
  });
  useEffect(() => {
    if (token) {
      runCanInvite();
    }
  }, [token]);
  return {
    canInvite: data,
    loading,
  };
}

export function useDeadline() {
  const { token } = useApp();
  const {
    data = false,
    loading,
    run: runGetDeadline,
  } = useRequest(getDeadline, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
  });
  useEffect(() => {
    if (token) {
      runGetDeadline();
    }
  }, [token]);
  return {
    deadline: data,
    loading,
  };
}
