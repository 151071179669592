import styles from './Header.module.scss';
import { Divider, NavBar, Popup, Tag } from 'antd-mobile';
import { MoreOutline } from 'antd-mobile-icons';
import { Link } from 'react-router-dom';
import { useApp } from 'App/useApp';
import { useCanInvite, useDeadline, useTokenData } from 'hooks/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { RoleEnum } from 'api/dto';

export function Header() {
  const { navbarHook } = useApp();
  const { visible, setVisible } = navbarHook;
  const tokenData = useTokenData();
  const { canInvite } = useCanInvite();
  const { deadline } = useDeadline();
  const { t } = useTranslation();

  return (
    <header className={styles['header']}>
      <NavBar
        back={null}
        right={
          <MoreOutline
            fontSize={30}
            onClick={() => {
              setVisible(true);
            }}
          />
        }
      >
        Deeper Network
      </NavBar>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        position="left"
        bodyStyle={{ width: '60vw' }}
      >
        <div className={styles['user-info-box']}>
          <div className={styles['address']}>{tokenData.username}</div>
          {deadline.referer?.status === false && (
            <div className={styles['deadline-box']}>
              账户重置时间：
              <div className={styles['time-box']}>
                <Tag color="danger">{moment(deadline.referer?.deadline).format('YYYY-MM-DD HH:mm:ss')}</Tag>
              </div>
              <div className={styles['info-box']}>请在这个时间之前成功购买一单，否则将失去被邀请资格。</div>
            </div>
          )}
          {deadline.reward?.status === false && (
            <>
              {deadline.reward.deadline === 0 ? (
                <div>
                  <Tag color="danger">已失去订单奖励资格</Tag>
                </div>
              ) : (
                <div className={styles['deadline-box']}>
                  订单奖励失效时间：
                  <div className={styles['time-box']}>
                    <Tag color="danger">{moment(deadline.reward?.deadline).format('YYYY-MM-DD HH:mm:ss')}</Tag>
                  </div>
                  <div className={styles['info-box']}>
                    请在这个时间之前成功邀请2位下单用户，否则将失去获得订单奖励的资格。
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {tokenData.role === RoleEnum.MainAgent ? (
          <div className={styles['nav-box']}>
            <Link
              className={styles['nav-item']}
              to="/invitation"
              onClick={() => {
                setVisible(false);
              }}
            >
              ADSC节点
            </Link>
            <Link
              className={styles['nav-item']}
              to="/reward"
              onClick={() => {
                setVisible(false);
              }}
            >
              节点奖励
            </Link>
            <Link
              className={styles['nav-item']}
              to="/deeperChain"
              onClick={() => {
                setVisible(false);
              }}
            >
              我的ADSC
            </Link>
            <Link
              className={styles['nav-item']}
              to="/withdraw"
              onClick={() => {
                setVisible(false);
              }}
            >
              提现管理
            </Link>
            <Link
              className={styles['nav-item']}
              to="/information"
              onClick={() => {
                setVisible(false);
              }}
            >
              信息统计
            </Link>
          </div>
        ) : (
          <div className={styles['nav-box']}>
            <Link
              className={styles['nav-item']}
              to="/"
              onClick={() => {
                setVisible(false);
              }}
            >
              首页
            </Link>
            {canInvite && (
              <Link
                className={styles['nav-item']}
                to="/invite"
                onClick={() => {
                  setVisible(false);
                }}
              >
                邀请好友
              </Link>
            )}
            {canInvite && (
              <Link
                className={styles['nav-item']}
                to="/invitation"
                onClick={() => {
                  setVisible(false);
                }}
              >
                ADSC节点
              </Link>
            )}

            {canInvite && (
              <Link
                className={styles['nav-item']}
                to="/reward"
                onClick={() => {
                  setVisible(false);
                }}
              >
                节点奖励
              </Link>
            )}
            <Link
              className={styles['nav-item']}
              to="/deeperChain"
              onClick={() => {
                setVisible(false);
              }}
            >
              我的ADSC
            </Link>
            <Link
              className={styles['nav-item']}
              to="/order"
              onClick={() => {
                setVisible(false);
              }}
            >
              我的订单
            </Link>
            <Link
              className={styles['nav-item']}
              to="/withdraw"
              onClick={() => {
                setVisible(false);
              }}
            >
              提现管理
            </Link>
          </div>
        )}
      </Popup>
    </header>
  );
}
