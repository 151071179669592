import { useRequest } from 'ahooks';
import { getInvitation, releaseReferer } from 'api/user';
import moment from 'moment';
import styles from './Invitation.module.scss';
import { SpinLoading, Button, Dialog, Toast } from 'antd-mobile';
import React, { useMemo, useState } from 'react';
import { DeleteOutline, AddOutline, MinusOutline } from 'antd-mobile-icons';

type InvitationItem = {
  _id: string;
  username: string;
  refererTime: number;
  referralCode: boolean;
  children: InvitationItem[];
};

const NodeWord = ['近距节点', '中距节点', '远距节点'];

export function Invitation() {
  const [treeOpenMap, setTreeOpenMap] = useState<{ [key: string]: boolean }>({});
  const {
    data: list = [],
    loading: listLoading,
    refresh,
  } = useRequest(getInvitation, {
    onSuccess(res) {
      console.log(res);
    },
  });

  const { run: runRelease } = useRequest(releaseReferer, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      Toast.show({
        icon: 'success',
        content: '释放成功',
      });
      refresh();
    },
  });

  function renderTr(list: InvitationItem[], level = 0): any {
    return list.map((it: InvitationItem) => (
      <React.Fragment key={it.username}>
        <tr>
          <td style={{ wordBreak: 'break-all' }}>
            <span style={{ display: 'inline-block', width: level * 10 }}></span>
            {it.children.length && (
              <Button
                onClick={() => {
                  treeOpenMap[it.username] = !treeOpenMap[it.username];
                  setTreeOpenMap({ ...treeOpenMap });
                }}
                fill="none"
                size="mini"
              >
                {!!treeOpenMap[it.username] ? <MinusOutline /> : <AddOutline />}
              </Button>
            )}
            {NodeWord[level] || level + 1} - {it.username}
          </td>
          <td>{it.refererTime ? moment(it.refererTime).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
          {!it.referralCode && level === 0 && (
            <td>
              <Button
                onClick={() => {
                  Dialog.confirm({
                    content: '是否要释放该节点',
                    onConfirm: async () => {
                      runRelease(it._id);
                    },
                  });
                }}
                fill="outline"
                color="danger"
                size="mini"
              >
                <DeleteOutline />
              </Button>
            </td>
          )}
        </tr>

        {it.children.length && !!treeOpenMap[it.username] ? renderTr(it.children, level + 1) : <></>}
      </React.Fragment>
    ));
  }
  return (
    <div>
      <div className={styles['total-box']}>
        <div>ADSC节点</div>
      </div>
      {listLoading ? (
        <div className={styles['loading-box']}>
          <SpinLoading></SpinLoading>
        </div>
      ) : (
        <div className={styles['table-box']}>
          <table>
            <thead>
              <tr>
                <th>地址</th>
                <th style={{ width: 100 }}>节点创建时间</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderTr(list)}</tbody>
          </table>
        </div>
      )}
    </div>
  );
}
