import { useRequest } from 'ahooks';
import { getReward } from 'api/user';
import { Tabs, SpinLoading, CalendarPicker, List } from 'antd-mobile';
import moment from 'moment';
import styles from './Information.module.scss';
import { useState, useEffect, useMemo } from 'react';

export type RewardItemDto = {
  amount: number;
  _id: string;
  createdAt: string;
  orderId: string;
  reason: string;
  topReferralCode: string;
};

export function Information() {
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<RewardItemDto[]>([]);

  const [topReferralCodeList, setTopReferralCodeList] = useState<string[]>([]);
  const [activeCode, setActiveCode] = useState('');

  const {
    run: runGetReward,
    loading: listLoading,
    data: dataList,
  } = useRequest(getReward, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      let trl: string[] = [];
      res.forEach((it: RewardItemDto) => {
        if (trl.indexOf(it.topReferralCode) === -1) {
          trl.push(it.topReferralCode);
        }
      });
      setTopReferralCodeList([...trl]);
      setActiveCode(trl[0]);
    },
  });

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState<[Date, Date] | null>();

  const applyDateRange = (dateRange: [Date, Date] | null) => {
    if (!dateRange) {
      return;
    }
    runGetReward(dateRange[0].getTime(), dateRange[1].getTime());
  };

  const onChangeDateRange = (selectedDate: [Date, Date] | null): [Date, Date] => {
    if (!selectedDate) {
      return [new Date(), new Date()];
    }
    const startOfWeek = moment(selectedDate[0]).startOf('isoWeek');
    const endOfWeek = moment(startOfWeek).add(7, 'days');
    setDateRange([startOfWeek.toDate(), endOfWeek.toDate()]);
    return [startOfWeek.toDate(), endOfWeek.toDate()];
  };

  useEffect(() => {
    applyDateRange(onChangeDateRange([new Date(), new Date()]));
  }, []);

  const [activeKey, setActiveKey] = useState<string>('MAINAGENT_LEFT');
  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (!dataList) {
      return;
    }
    let list: RewardItemDto[] = [];
    let total = 0;
    dataList.forEach((it: RewardItemDto) => {
      if (it.topReferralCode === activeCode && it.reason === activeKey) {
        list.push(it);
        total += it.amount / 1;
      }
    });
    setList([...list]);
    setTotal(Number(total.toFixed(5)) / 1);
  }, [dataList, activeKey, activeCode]);

  return (
    <>
      <List.Item
        className={styles['adm-list-item-content-main']}
        onClick={() => {
          setDatePickerOpen(true);
        }}
      >
        {dateRange ? (
          <span>
            {moment(dateRange[0]).format('YYYY-MM-DD')} - {moment(dateRange[1]).format('YYYY-MM-DD')}
          </span>
        ) : (
          <span>选择查询时间</span>
        )}
        <CalendarPicker
          visible={datePickerOpen}
          selectionMode="range"
          value={dateRange}
          min={new Date('2023/12/01')}
          onChange={onChangeDateRange}
          onClose={() => setDatePickerOpen(false)}
          onMaskClick={() => setDatePickerOpen(false)}
          onConfirm={() => {
            if (dateRange) {
              applyDateRange(dateRange);
            }
          }}
        />
      </List.Item>
      <Tabs
        style={{
          '--title-font-size': '12px',
        }}
        activeKey={activeCode}
        onChange={active => {
          setActiveCode(active);
        }}
      >
        {topReferralCodeList.map(it => {
          return <Tabs.Tab key={it} title={it}></Tabs.Tab>;
        })}
      </Tabs>
      {activeCode && (
        <div>
          <div className={styles['total-box']}>
            <div>总奖励：</div> <div style={{ fontSize: 18 }}> {total} USDT</div>
          </div>
          <Tabs
            style={{
              '--title-font-size': '12px',
            }}
            activeKey={activeKey}
            onChange={handleTabChange}
          >
            <Tabs.Tab title="节点收益" key="MAINAGENT_LEFT"></Tabs.Tab>
            <Tabs.Tab title="总代理奖励" key="MAINAGENT"></Tabs.Tab>
          </Tabs>
          <div>
            {listLoading ? (
              <div className={styles['loading-box']}>
                <SpinLoading></SpinLoading>
              </div>
            ) : (
              <div className={styles['table-box']}>
                <table>
                  <thead>
                    <tr>
                      <th>订单编号</th>
                      <th>奖励</th>
                      <th>奖励时间</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((it: any) => {
                      return (
                        <tr key={it._id}>
                          <td style={{ wordBreak: 'break-all' }}>{it.orderId}</td>
                          <td>{it.amount} USDT</td>
                          <td>{moment(it.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
